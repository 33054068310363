export default () => {
  return {
    path: '/add-ons',
    component: () => import('@modules/DefaultView.vue'),
    children: [
      {
        path: '',
        name: 'add-ons',
        component: () => import('@modules/addOns/views/Home.vue'),
      },
    ],
  };
};
