import { ref } from 'vue';
import errorHandler from '@/utils/error.handler';
import axios from 'axios';
import { configService, configuration, token } from '@/services/api-config';

export const total = ref(0);

const apiService = axios.create({
  baseURL: configService.coreUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

apiService.interceptors.request.use(
  (config) => configuration(config),
  (error) => {
    Promise.reject(error.response || error.message);
  },
);

function callbackRequest(res) {
  if (res.ok) {
    return res.json();
  }
  if (res.status === 401) {
    window.location.href = import.meta.env.VITE_SELLIA_LOGIN_AUTH;
  }

  // throw new ApiError(res.statusText, res.json())
  errorHandler.logErrors(res).then();
}

export function simpleRequest(url, options) {
  return fetch(url, options).then(callbackRequest);
}

export function request(url, options) {
  total.value += 1;
  return simpleRequest(url, options).finally(() => {
    total.value -= 1;
  });
}

export function useApi() {
  return {
    get: (route, params) => {
      return apiService.get(
        `${configService.coreUrl}${route}`,
        { params },
      );
    },
    post: (route, body) => {
      return apiService.post(
        `${configService.coreUrl}${route}`,
        body,
      );
    },
    put: (route, body, params) => {
      return apiService.put(
        `${configService.coreUrl}${route}`,
        body,
        { params },
      );
    },
  };
}

export function useApiICR() {
  const url = configService.icrUrl;

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return {
    url,
    options,
    get: (route, params) => {
      return apiService.get(
        `${url}${route}`,
        { params },
      );
    },
    post: (route, body, params) => {
      return apiService.post(
        `${url}${route}`,
        body,
        { params },
      );
    },
    put: (route, body, params) => {
      return apiService.put(
        `${url}${route}`,
        body,
        { params },
      );
    },
    delete: (route, params) => {
      return apiService.delete(
        `${url}${route}`,
        { params },
      );
    },
    formData: (route, body) => {
      return apiService.post(
        `${url}${route}`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },
  };
}
