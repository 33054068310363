import ErrorHandler from '@/utils/error.handler';
import { apolloClient, onLogout } from '@/vue-apollo';

const redirectUrl = import.meta.env.VITE_REDIRECT_URL ?? 'https://app.heycenter.com';

async function executeQuery(id, query, variables, cache = true, options = {}) {
  try {
    const fetchPolicy = cache ? 'cache-first' : 'no-cache';

    const { data: result } = await apolloClient.query({
      query,
      variables,
      fetchPolicy,
      ...options,
    });
    return result[id] || [];
  } catch (e) {
    ErrorHandler.logErrors(e);
    if (e.networkError && e.networkError.statusCode === 401) {
      onLogout(apolloClient);
      window.location.href = redirectUrl;
    }
  }

  return [];
}

export default executeQuery;
