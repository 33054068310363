import { defineRule, configure } from 'vee-validate';
import { all } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';

Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});

defineRule(
  'greater_than',
  (value, [target], ctx) => Number(value) > Number(ctx.form[target])
);

defineRule('lower_than', (value, [target], ctx) => {
  if (ctx.form[target]) {
    return Number(value) < Number(ctx.form[target]);
  }

  return true;
});

export default () => {
  configure({
    generateMessage: localize({
      en: {
        code: en.code,
        messages: {
          ...en.messages,
          greater_than: 'The {field} field may greater than {minimumAmount}',
          lower_than: 'The {field} field may lower than {maxAmount}',
        },
      },
      es: {
        code: es.code,
        messages: {
          ...es.messages,
          greater_than: 'El campo {field} debe ser mayor que {minimumAmount}',
          lower_than: 'El campo {field} debe ser menor que {maxAmount}',
        },
      },
    }),
  });
};
