import tiersService from '@/services/tiers';
import errorHandler from '@/utils/error.handler';

export const tiers = {
  namespaced: true,
  state: {
    allTiers: [],
  },
  getters: {
    getTiers(state) {
      return state.allTiers;
    },
  },
  mutations: {
    setTiers(state, payload) {
      state.allTiers = payload;
    },
  },
  actions: {
    async fetchTiers({ commit }) {
      try {
        const tiers = await tiersService.getTiers();
        commit('setTiers', tiers);
      } catch (error) {
        errorHandler.logErrors(error);
      }
    }
  }

};
