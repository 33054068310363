import { datadogRum } from '@datadog/browser-rum';

async function logErrors(err) {
  if (import.meta.env.VITE_DD_ENVIRONMENT === 'production') {
    datadogRum.addError(err);
  } else {
    console.error(err);
  }
}

export default {
  logErrors,
};
