export const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setHeaderItems(state, headerItems) {
    state.headerItems = headerItems;
  },
  addBreadcrumb(state, breadcrumb) {
    state.headerItems.push(breadcrumb);
  },
  updateBreadcrumb(state, { index, value }) {
    state.headerItems.splice(index, 1, value);
  },
  removeBreadcrumb(state, index) {
    state.headerItems.splice(index, 1);
  },
  resetBreadcrumbs(state) {
    state.headerItems = [];
  },
};
