export default () => {
  return {
    path: '/surveys',
    component: () => import('@modules/chatbots/views/ChatBotInitialView.vue'),
    requiredPermissions: ['surveys_read', 'surveys_write'],
    meta: {
      priority: 10,
      site: {
        t: 'app.surveys',
      },
      requiredPermissions: ['surveys_read', 'surveys_write'],
    },
    children: [
      {
        path: '',
        name: 'surveys',
        component: () => import('@modules/surveys/views/IndexView.vue'),
        meta: {
          priority: 10.1,
        }
      },
      {
        path: ':id',
        name: 'survey-show',
        component: () => import('@modules/surveys/views/QuestionsView.vue'),
        meta: {
          group: 'surveys',
          requiredPermissions: ['surveys_read', 'surveys_write'],
          site: {
            t: (store, { id }) => {
              const survey = store.getters['surveys/current'];

              return survey?.id === id ? survey?.name : '-';
            },
          }
        },
      },
    ],
  };
};
