import AutorenewIcon from 'vue-material-design-icons/Autorenew.vue';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';
import ClockOutlineIcon from 'vue-material-design-icons/ClockOutline.vue';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
import CompareIcon from 'vue-material-design-icons/Compare.vue';
import MessageIcon from 'vue-material-design-icons/Message.vue';
import WindowMinimizeIcon from 'vue-material-design-icons/WindowMinimize.vue';

export default function registerIcons(app) {
  app.component('AutorenewIcon', AutorenewIcon);
  app.component('ArrowLeftIcon', ArrowLeftIcon);
  app.component('ClockOutlineIcon', ClockOutlineIcon);
  app.component('CloseCircleIcon', CloseCircleIcon);
  app.component('CompareIcon', CompareIcon);
  app.component('MessageIcon', MessageIcon);
  app.component('WindowMinimizeIcon', WindowMinimizeIcon);
}
