export const internalChat = {
  namespaced: true,
  state: {
    messages: [],
  },
  getters: {
    agentMessages(state) {
      return (id) => state.messages.filter(({ to, from }) => to === id || from === id);
    },
  },
  mutations: {
    addMessage(state, props) {
      state.messages.push(props);
    },
  },
};
