import dataBankActions from './actions/dataBankActions';

const setDefaulState = () => ({
  databank: {
    text: [],
    image: null,
    document: null,
    video: null,
    location: null,
  },
  templates: [],
  wabaAccountId: null,
  waProvider: null,
});

export const databank = {
  namespaced: true,
  state: setDefaulState(),
  getters: {
    getDataBank(state) {
      return state.databank;
    },
    getTabSection(state) {
      return (type) => state.databank[type];
    },
    getTemplates(state) {
      return state.templates;
    },
  },
  mutations: {
    setWabaAccountId(state, wabaAccountId) {
      state.wabaAccountId = wabaAccountId;
    },
    setWaProvider(state, waProvider) {
      state.waProvider = waProvider;
    },
    setDataBank(state, { type, payload }) {
      state.databank[type] = payload;
    },
    updateDataBank(state, { payload, index }) {
      state.databank[payload.type][index] = payload;
    },
    deleteDataBank(state, { index, type }) {
      const newArr = [...state.databank[type]];
      newArr.splice(index, 1);
      state.databank[type] = newArr;
    },
    setTemplates(state, payload) {
      state.templates = payload;
    },
    addTemplate(state, payload) {
      state.templates.push(payload);
    },
    updateTemplate(state, { id, status }) {
      const index = state.templates.findIndex((temp) => temp.id === id);
      if (index !== -1) {
        const template = state.templates[index];
        state.templates[index] = { ...template, status };
      }
    },
    clear(state) {
      Object.assign(state, setDefaulState());
    },
  },
  actions: {
    ...dataBankActions,
  },
};
