export const template = {
  namespaced: true,
  state: {
    clientList: [],
    templateSelected: null,
    loaderClientList: false,
    loaderAvailableTemplate: false,
    alert: {
      status: false,
      type: 'info',
      msg: null,
    },
  },
  mutations: {
    setClientList(state, payload) {
      state.clientList = payload;
    },
    deleteClientList(state, payload) {
      const index = state.clientList.findIndex((client) => client.id === payload);
      if (index !== -1) {
        state.clientList.splice(index, 1);
      }
    },
    deleteClientsList(state, payload) {
      state.clientList = payload;
    },
    toogleClientList(state, payload) {
      state.loaderClientList = payload;
    },
    toogleAvailableTemplate(state, payload) {
      state.loaderAvailableTemplate = payload;
    },
    setTemplateSelected(state, payload) {
      state.templateSelected = payload;
    },
    setAlert(state, { status, type, msg }) {
      state.alert = {
        status,
        type,
        msg,
      };
      if (status) {
        setTimeout(() => {
          state.alert = {
            status: false,
            type: 'info',
            msg: null,
          };
        }, 5000);
      }
    },
  },
};
