export const roles = {
  namespaced: true,
  state: {
    roleList: [],
  },
  getters: {
    getRoleList(state) {
      return state.roleList;
    },
  },
  mutations: {
    setRoleList(state, data) {
      state.roleList = data;
    },
    ADD_ROLE(state, role) {
      state.roleList.push(role);
    },
    UPDATE_ROLE(state, newData) {
      const { _id } = newData;
      const index = state.roleList.findIndex(({ _id: id }) => id === _id);
      if (index !== -1) {
        state.roleList[index] = newData;
      }
    },
  },
  actions: {
    addRole({ commit }, role) {
      commit('ADD_ROLE', role);
    },
    updateRole({ commit }, role) {
      commit('UPDATE_ROLE', role);
    },
  },
};
