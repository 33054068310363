<template>
  <div class="vfm__header">
    <div class="vfm__header--title">
      <slot name="title">
        <h5 class="text-lg py-[3px] text-neutral-600" :class="variant" data-test-id="modalTitleButton">
          {{ title }}
        </h5>
      </slot>
    </div>

    <button
      type="button"
      class="button xs icon"
      data-test-id="modalCloseButton"
      @click="$emit('close')"
    >
      <icon icon="mdi:close" class="icon-md" />
    </button>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  components: { Icon },
  props: {
    title: {
      type: String,
    },
    variant: {
      type: String,
    },
  },
  emits: ['close'],
};
</script>
