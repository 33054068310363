<template>
  <router-view />
</template>

<script>
import { h } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { setI18nLanguage } from '@/i18n';
import { LOCALE_LAGUAGE_CHANGE } from '@/eventTypes';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import ReloadPrompt from '@/components/ReloadPrompt.vue';

export default {
  methods: {
    changeLanguage(lang) {
      setI18nLanguage(lang);
      this.$moment.locale(lang);
      this.emitter.emit(LOCALE_LAGUAGE_CHANGE, lang);
    },
  },
  mounted() {
    const lang = this.$store.getters['shared/getLanguage'];
    this.changeLanguage(lang);
  },
  setup() {
    const { t } = useI18n();

    const { updateServiceWorker } = useRegisterSW({
      onNeedRefresh() {
        toast.info(
          ({ closeToast, toastProps }) => h(ReloadPrompt, {
            closeToast,
            toastProps,
            t,
          }),
          {
            delay: 20,
            theme: 'light',
            icon: false,
            closeOnClick: false,
            closeButton: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            onClose: () => updateServiceWorker(),
          }
        );
      },
    });
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
