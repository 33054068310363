import campaignsByCompanyIdQuery from '@/graphql/queries/campaigns/byCompanyId.gql';
import channelByCompanyIdGql from '@/graphql/queries/channels/channelByCompanyId.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  fetchCampaigns({ commit }, id) {
    return executeQuery(
      'campaignsByCompanyId',
      campaignsByCompanyIdQuery,
      {
        companyId: id,
      },
      false,
    )
      .then((result) => {
        commit('setCampaigns', result);
        return 'done';
      })
      .catch((err) => {
        errorHandler.logErrors(err);
        return 'error';
      });
  },
  updateCampaign({ commit, getters }, payload) {
    const campaigns = getters.getCampaigns;
    const index = campaigns.findIndex((c) => c.id === payload.id);
    campaigns[index] = payload;
    commit('setCampaigns', campaigns);
    commit('setCurrentCampaign', payload);
  },
  selectCampaign({ commit, getters }, id) {
    const campaigns = getters.getCampaigns;
    const campaign = campaigns.find((c) => c.id === id);
    commit('setCurrentCampaign', campaign);
  },
  addCampaign({ commit, getters }, payload) {
    const campaigns = getters.getCampaigns;
    campaigns.push(payload);
    commit('setCampaigns', campaigns);
  },
  fetchChannelsByCompany({ commit }, id) {
    return executeQuery(
      'channelByCompanyId',
      channelByCompanyIdGql,
      {
        company: id,
      },
      false,
    )
      .then((result) => {
        commit('setChannelsByCompany', result);
        return 'done';
      })
      .catch((err) => {
        errorHandler.logErrors(err);
        return 'error';
      });
  },
};
