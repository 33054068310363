export const subscription = {
  namespaced: true,
  state: {
    subscription: null,
    payment: null,
  },
  getters: {
    getPayment(state) {
      return state.payment;
    },
  },
  mutations: {
    setPayment(state, payload) {
      state.payment = payload;
    },
  },
};
