export default (i18n) => {
  return {
    path: '/chatbots',
    name: 'module-flows',
    component: () => import('@modules/chatbots/views/ChatBotInitialView.vue'),
    requiredPermissions: ['flows_read', 'flows_write'],
    meta: {
      priority: 3,
      site: {
        name: i18n.global.t('app.flows'),
        t: 'app.flows',
      },
    },
    children: [
      {
        path: '',
        name: 'chatbots',
        meta: {
          priority: 3.1,
          group: 'chatbots',
          title: i18n.global.t('app.editor.index'),
          requiredPermissions: ['flows_read', 'flows_write'],
        },
        component: () => import('@modules/chatbots/views/Index.vue'),
      },
      {
        path: ':chatBotId',
        name: 'chatbot-list',
        component: () => import('@modules/chatbots/views/ChatBotView.vue'),
        meta: {
          group: 'chatbots',
          title: i18n.global.t('app.editor.edit'),
          requiredPermissions: ['flows_read', 'flows_write'],
          site: {
            t: (store) => {
              const chatbot = store.getters['flows/currentChatBot'];

              return chatbot?.name || '-';
            },
          }
        },
      },
    ],
  };
};
