export default (i18n) => {
  return {
    path: '/settings',
    component: () => import('@modules/DefaultView.vue'),
    meta: {
      priority: 11,
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        t: 'app.configuration',
        name: i18n.global.t('app.notifications'),
      },
      requiredPermissions: ['configuration_facturation_read', 'configuration_facturation_write'],
    },
    children: [
      {
        path: 'billing',
        component: () => import('@modules/configuration/views/billing/Billing.vue'),
        meta: {
          priority: 11.2,
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            t: 'app.billing',
          },
          requiredPermissions: ['configuration_facturation_read', 'configuration_facturation_write'],
        },
        children: [
          {
            path: '',
            name: 'movements',
            meta: {
              forAuth: true,
              layout: 'navigation-layout',
              site: {
                t: 'configuration.movements',
              },
              requiredPermissions: ['configuration_facturation_read', 'configuration_facturation_write'],
            },
            component: () => import('@modules/configuration/views/billing/Movements.vue'),
          },
          {
            path: 'billing-data',
            name: 'company-billing-data',
            meta: {
              priority: 11.3,
              forAuth: true,
              layout: 'navigation-layout',
              site: {
                t: 'configuration.billing-details',
              },
              requiredPermissions: ['configuration_facturation_read', 'configuration_facturation_write'],
            },
            component: () => import('@modules/configuration/views/billing/BillingData.vue'),
          },
          {
            path: 'payment-methods',
            name: 'billing-payment-methods',
            meta: {
              priority: 11.4,
              forAuth: true,
              layout: 'navigation-layout',
              site: {
                t: 'configuration.payment-methods',
              },
              requiredPermissions: ['configuration_facturation_read', 'configuration_facturation_write'],
            },
            component: () => import('@modules/configuration/views/billing/PaymentMethods.vue'),
          },
        ]
      },
      {
        path: 'users',
        name: 'company-team',
        component: () => import('@/modules/configuration/views/Team.vue'),
        meta: {
          priority: 11.5,
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            t: 'configuration.team',
          },
          requiredPermissions: ['configuration_team_read', 'users_write'],
        },
      },
      {
        path: 'configuration',
        name: 'configuration',
        component: () => import('@/modules/configuration/views/Company.vue'),
        meta: {
          priority: 11.1,
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            t: 'configuration.company',
            name: i18n.global.t('app.notifications'),
          },
        },
      },
      {
        path: 'profiles',
        name: 'profiles',
        component: () => import('@/modules/configuration/views/Profiles.vue'),
        meta: {
          priority: 11.6,
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            t: 'app.profiles',
            name: i18n.global.t('app.profiles'),
          },
        },
      },
      {
        path: 'profiles/add',
        name: 'profiles-add',
        component: () => import('@/modules/configuration/views/ProfileAdd.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            t: 'app.profiles-add',
            name: i18n.global.t('app.profiles'),
          },
        },
      },
    ],
  };
};
