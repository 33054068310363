export function validateHeight(el) {
  const clientHeight = el.clientHeight;
  const scrollHeight = el.scrollHeight;

  return clientHeight < scrollHeight;
}

export function validateWidth(el) {
  const clientWidth = el.clientWidth;
  const scrollWidth = el.scrollWidth;

  return clientWidth < scrollWidth;
}

export function addClassScroll(el, bindings = {}) {
  if (validateHeight(el) && !el.classList.contains('has-scroll')) {
    el.classList.add('has-scroll');
    (bindings.oldValue || []).forEach((className) => {
      el.classList.remove(className);
    });

    (bindings.value || []).forEach((className) => {
      el.classList.add(className);
    });
  } else if (!validateHeight(el) && el.classList.contains('has-scroll')) {
    el.classList.remove('has-scroll');
    (bindings.oldValue || []).forEach((className) => {
      el.classList.remove(className);
    });
  }

  const extraWidth = (el.offsetWidth - el.clientWidth);
  if (extraWidth > 0) {
    el.classList.add('dynamic');
  } else {
    el.classList.remove('dynamic');
  }
}

export function addClassScrollX(el, bindings = {}) {
  if (validateWidth(el) && !el.classList.contains('has-scroll-x')) {
    el.classList.add('has-scroll-x');
    (bindings.oldValue || []).forEach((className) => {
      el.classList.remove(className);
    });

    (bindings.value || []).forEach((className) => {
      el.classList.add(className);
    });
  } else if (!validateWidth(el) && el.classList.contains('has-scroll-x')) {
    el.classList.remove('has-scroll-x');
    (bindings.oldValue || []).concat(bindings.value || []).forEach((className) => {
      el.classList.remove(className);
    });
  }

  const extraWidth = (el.offsetHeight - el.clientHeight) + 10;
  if (extraWidth > 0) {
    el.classList.add('dynamic');
  } else {
    el.classList.remove('dynamic');
  }
}

export function resizeObserver(el, callback) {
  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      callback(entry.target);
    }
  });

  resizeObserver.observe(el);
}

export const scrollY = {
  mounted(el, binding) {
    addClassScroll(el);
    resizeObserver(el, (el) => addClassScroll(el, binding));
  },
  update(el, binding) {
    addClassScroll(el, binding);
  },
  beforeUnmount(el) {
    el.classList.remove('has-scroll');
    el.classList.remove('dynamic');
  },
};

export const scrollX = {
  mounted(el, binding) {
    addClassScrollX(el, binding);
    resizeObserver(el, (el) => addClassScrollX(el, binding));
  },
  update(el, binding) {
    addClassScrollX(el, binding);
  },
  beforeUnmount(el) {
    el.classList.remove('has-scroll-x');
    el.classList.remove('dynamic');
  },
};
