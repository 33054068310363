export default (i18n) => {
  return {
    path: '/templates',
    component: () => import('@modules/DefaultView.vue'),
    name: 'module-templates',
    meta: {
      priority: 5,
      forAuth: true,
      site: {
        name: i18n.global.t('templates.title'),
        t: 'templates.title',
      },
      requiredPermissions: ['templates_send'],
    },
    children: [
      {
        path: '',
        name: 'templates-list',
        component: () => import('@modules/templates/views/List.vue'),
        meta: {
          priority: 5.1,
          forAuth: true,
          site: {
            name: i18n.global.t('templates.title'),
          },
          requiredPermissions: ['templates_send'],
        },
      },
      {
        path: 'contacts/:template_id',
        name: 'template-contacts',
        component: () => import('@modules/templates/views/Contacts.vue'),
        meta: {
          forAuth: true,
          site: {
            name: i18n.global.t('templates.title'),
          },
          requiredPermissions: ['templates_send'],
        },
      },
      {
        path: 'send',
        component: () => import('@modules/templates/views/Send.vue'),
        meta: {
          priority: 5.2,
          forAuth: true,
          site: {
            name: i18n.global.t('templates.title'),
            t: 'templates.new-send',
          },
          requiredPermissions: ['templates_send'],
        },
        children: [
          {
            path: '',
            name: 'templates-send-first-step',
            component: () => import('@modules/templates/views/SelectTemplate.vue'),
            meta: {
              forAuth: true,
              site: {
                t: 'templates.steps.firstStep',
              },
              requiredPermissions: ['templates_send'],
            },
          },
          {
            path: 'contacts',
            name: 'templates-send-second-step',
            component: () => import('@modules/templates/views/SearchContacts.vue'),
            meta: {
              forAuth: true,
              site: {
                t: 'templates.steps.secondStep',
              },
              requiredPermissions: ['templates_send'],
            },
          },
          {
            path: 'configuration',
            name: 'templates-send-third-step',
            component: () => import('@modules/templates/views/ConfigureSubmission.vue'),
            meta: {
              forAuth: true,
              site: {
                t: 'templates.steps.thirdStep',
              },
              requiredPermissions: ['templates_send'],
            },
          },
          {
            path: 'confirmation',
            name: 'templates-send-fourth-step',
            component: () => import('@modules/templates/views/SubmissionSummary.vue'),
            meta: {
              forAuth: true,
              site: {
                t: 'templates.steps.fourthStep',
              },
              requiredPermissions: ['templates_send'],
            },
          },
        ],
      },
    ],
  };
};
