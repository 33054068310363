import apiService from './api-service';

const tiersService = {};

/**
 * Retrieve all tiers
 * @return tiers
 */
tiersService.getTiers = () => apiService
  .get('/tiers')
  .then((res) => res.data);

export default tiersService;
