import store from '@/store';

const AUTH_TOKEN = 'hey-access-token';
const token = localStorage.getItem(AUTH_TOKEN);

const configService = {
  apiUrl: `${import.meta.env.VITE_API_AUTH_URL}/api/v1/`,
  coreUrl: `${import.meta.env.VITE_API_BASE_URL}/api/v1/`,
  queuesUrl: `${import.meta.env.VITE_API_QUEUE_URL}/api/`,
  icrUrl: `${import.meta.env.VITE_ICR_URL}`,
};

const configuration = (config) => {
  const newConf = config;
  const accessToken = `Bearer ${localStorage.getItem(AUTH_TOKEN)}`;
  const locale = store.getters['shared/getLocale'];

  if (accessToken) {
    newConf.headers.Authorization = accessToken;
    newConf.headers['accept-language'] = locale ?? 'es';
  }
  return newConf;
};

export { configService, configuration, token };
