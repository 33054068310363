export const getters = {
  activeConversation(state) {
    return state.conversations;
  },
  chatIsLoading(state) {
    return state.conversationStates.loading ?? false;
  },
  chatFirstLoad(state) {
    return (clientId) => {
      if (state.conversationStates[clientId]) {
        return state.conversationStates[clientId].firstLoad ?? true;
      }

      return true;
    };
  },
  chatTopReached(state) {
    return state.conversationStates.topReached ?? false;
  },
  chatOldestMessageId(state) {
    if (state.messages.length > 0) {
      const oldestMessage = state.messages[0];
      return oldestMessage.id;
    }
    return null;
  },
};
