export default (i18n) => {
  return {
    path: '/conversations',
    name: 'conversations',
    component: () => import('@modules/conversations/views/Conversations.vue'),
    meta: {
      priority: 8,
      forAuth: true,
      site: {
        name: i18n.global.t('app.notifications'),
        t: 'app.conversations'
      },
      requiredPermissions: ['configuration_conversation_read', 'configuration_conversation_write'],
    },
  };
};
