<template>
    <hcc-button
      v-if="buttons.includes('cancel')"
      size="sm"
      variant="fill"
      color="secondary"
      data-test-id="button_cancel"
      @click="handleCancel"
    >
      {{ $t("shared-components.modal.cancel") }}
    </hcc-button>
    <hcc-button
      v-if="buttons.includes('confirm')"
      :disabled="disabled"
      size="sm"
      class="modal__buttons--button"
      :color="getButtonColor"
      data-test-id="button_confirm"
      @click="handleConfirm"
    >
      {{ $t("shared-components.modal.confirm") }}
    </hcc-button>
    <hcc-button
      v-if="buttons.includes('exit')"
      size="sm"
      variant="fill"
      color="primary"
      @click="handleCancel"
    >
      {{ $t("shared-components.modal.exit") }}
    </hcc-button>
</template>

<script>
import HccButton from '../HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  props: {
    variant: {
      type: String,
      default: 'alert',
    },
    buttons: {
      type: Array,
      default: () => ['cancel', 'confirm'],
    },
    handleConfirm: {
      type: Function,
    },
    handleCancel: {
      type: Function,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    getButtonColor() {
      let color = 'primary';

      if (this.variant === 'error') {
        color = 'alert';
      } else if (this.variant === 'info') {
        color = 'secondary';
      }
      return color;
    },
  },
};
</script>
