export const surveys = {
  namespaced: true,
  state: {
    current: {},
  },
  getters: {
    current: (state) => state.current,
  },
  mutations: {
    setCurrent(state, flow) {
      state.current = flow;
    },
  },
};
