import store from '@/store';
import { SYSTEM_ROLES_PERMISSIONS } from '@/constants';

export function userCan(itemPermissions) {
  const { permissions, permissionsBlocked, role } = store.getters['shared/userInfo'];
  const requiredPermissions = Array.isArray(itemPermissions) ? itemPermissions : [itemPermissions];
  const isBlocked = requiredPermissions?.some((blocked) => permissionsBlocked?.includes(blocked));

  if (SYSTEM_ROLES_PERMISSIONS.includes(role) && !isBlocked) {
    return true;
  }

  const hasPermission = requiredPermissions?.some((permission) => permissions?.includes(permission));

  return !SYSTEM_ROLES_PERMISSIONS.includes(role) && hasPermission;
}

function validationWithPermissions(el, binding) {
  if (binding.value !== binding.oldValue) {
    if (userCan(binding.value)) {
      if (el._placeholder) {
        el._placeholder.parentNode.replaceChild(el, el._placeholder);
      }
    } else {
      if (!el._placeholder) {
        el._placeholder = document.createComment('v-auth');
      }
      if (el.parentNode) {
        el.parentNode.replaceChild(el._placeholder, el);
      }
    }
  }
}
export const auth = {
  mounted(el, binding) {
    if (!userCan(binding.value)) {
      el._placeholder = document.createComment('v-auth');
      if (el.parentNode) {
        el.parentNode.replaceChild(el._placeholder, el);
      } else {
        el.remove();
      }
    }
  },
  updated: validationWithPermissions,
};
