export const actions = {
  parseTitle(_, { title, params }) {
    if (params && params.title) {
      return title.replace('{{NAME}}', params.title);
    }
    return title;
  },
  addBreadcrumb({ state, commit, dispatch }, value) {
    const indexRoute = state.headerItems.findIndex(
      ({ fullPath }) => fullPath === value.fullPath
    );
    if (indexRoute !== -1) {
      commit('setHeaderItems', state.headerItems.slice(0, indexRoute + 1));
    } else if (
      value.meta?.title &&
      state.headerItems.every(({ group }) => group === value.meta?.group)
    ) {
      const breadcrumb = {
        group: value.meta?.group,
        fullPath: value.fullPath,
        title: dispatch('parseTitle', { title: value.meta.title, params: value.state }),
      };
      commit('addBreadcrumb', breadcrumb);
    } else if (value.meta?.title && value.meta?.group) {
      const breadcrumb = {
        group: value.meta?.group,
        fullPath: value.fullPath,
        title: dispatch('parseTitle', { title: value.meta.title, params: value.state }),
      };
      commit('setHeaderItems', [breadcrumb]);
    }
  },
  updateBreadcrumb({ state, commit }, value) {
    const indexRoute = state.headerItems.findIndex(
      ({ fullPath }) => fullPath === value.fullPath
    );

    if (indexRoute !== -1) {
      const item = state.headerItems[indexRoute];
      const updatedItem = {
        ...item,
        ...value,
      };
      commit('updateBreadcrumb', { index: indexRoute, value: updatedItem });
    }
  },
  removeBreadcrumb({ state, commit }, indexRoute = 0) {
    let finalIndex = indexRoute;
    if (indexRoute < 0 || indexRoute > state.headerItems.length - 1) {
      finalIndex = state.headerItems.length - 1;
    }
    commit('removeBreadcrumb', finalIndex);
  },
  navigateTo({ state, commit }, value) {
    const index = state.items.findIndex(({ id }) => id === value);
    commit('setItems', state.items.slice(0, index));
  },
  resetBreadcrumbs({ commit }) {
    commit('resetBreadcrumbs');
  },
};
